import {ItemCategory} from './item-category';

export class Item {
    identifier: string;
    name: string;
    weight: string;
    price: number;
    oldPrice: number;
    category: ItemCategory;
    description: string;

    constructor(identifier: string, name: string, weight: string, price: number, category: ItemCategory, description: string = '',
                oldPrice?: number) {
        this.identifier = identifier;
        this.name = name;
        this.weight = weight;
        this.price = price;
        this.oldPrice = oldPrice;
        this.category = category;
        this.description = description;
    }
}
