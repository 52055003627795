import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { debounceTime, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ApplicationStateService {

    public isNormalMobileResolution: BehaviorSubject<boolean> = new BehaviorSubject(this.isNormalMobile(window.innerWidth));
    public isLargeMobileResolution: BehaviorSubject<boolean> = new BehaviorSubject(this.isLargeMobile(window.innerWidth));
    public isTabletResolution: BehaviorSubject<boolean> = new BehaviorSubject(this.isTablet(window.innerWidth));
    public isDesktopResolution: BehaviorSubject<boolean> = new BehaviorSubject(this.isDesktop(window.innerWidth));
    public isTouchscreen: boolean;

    constructor() {
        const resize$ = Observable.fromEvent(window, 'resize')
            .pipe(
                debounceTime(200),
                map(() => window.innerWidth),
                distinctUntilChanged(),
                startWith(window.innerWidth),
                tap(width => this.isNormalMobileResolution.next(this.isNormalMobile(width))),
                tap(width => this.isLargeMobileResolution.next(this.isLargeMobile(width))),
                tap(width => this.isTabletResolution.next(this.isTablet(width))),
                tap(width => this.isDesktopResolution.next(this.isDesktop(width))),
            );
        resize$.subscribe();
        this.isTouchscreen = ('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0);
    }

    private isNormalMobile(width: number) {
        return width <= 550;
    }

    private isLargeMobile(width: number) {
        return 550 < width && width <= 768;
    }

    private isTablet(width: number) {
        return 768 < width && width <= 1024;
    }

    private isDesktop(width: number) {
        return 1024 < width;
    }
}
