import {AfterViewInit, Directive, ElementRef, HostListener, Input, Renderer} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
    selector: '[numbersOnly]'
})
export class NumbersOnlyDirective implements AfterViewInit {

    @Input() numbersOnly: any;
    @Input() integerOnly = false;

    constructor(private ngControl: NgControl, private el: ElementRef, private renderer: Renderer) {
    }

    @HostListener('input', ['$event']) onInputChange(event) {
        if (this.integerOnly) {
            const value = this.ngControl.value.replace(/[^0-9]*/g, '');
            this.updateModel(value);
            this.updateUI(value);
        } else {
            // For not integer we accept points right now, but later it can be , or else
            const value = this.ngControl.value.replace(/[^0-9.]*/g, '');
            this.updateModel(value);
            this.updateUI(value);
        }
    }

    private updateModel(value: number) {
        this.ngControl.control.setValue(value);
    }

    private updateUI(value: number) {
        this.ngControl.valueAccessor.writeValue(value);
    }

    ngAfterViewInit() {
        this.renderer.setElementAttribute(this.el.nativeElement, 'autocomplete', 'off');
    }
}
