import { Component } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { HamburgerComponent } from './hamburger/hamburger.component';
import { ApplicationStateService } from '../../services/application-state.service';
import { MenuService } from './service/menu.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

    //TODO: ScrollStrategyOptions: when on mobile landscape view it can be useful
    constructor(public stateService: ApplicationStateService,
                private readonly sso: ScrollStrategyOptions,
                private menuService: MenuService,
                private router: Router,
                public overlay: Overlay) {
    }

    openPanelWithBackdrop() {
        const config = new OverlayConfig({
            hasBackdrop: true,
            disposeOnNavigation: true,
            backdropClass: 'dark-backdrop',
            width: '100%',
            positionStrategy: this.overlay.position().global().centerHorizontally()
        });

        const overlayRef: OverlayRef = this.overlay.create(config);
        overlayRef.attach(new ComponentPortal(HamburgerComponent));
        overlayRef.backdropClick().subscribe(() => overlayRef.detach());
        this.menuService.overlayRef = overlayRef;
    }
}
