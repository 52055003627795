import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BasketService} from '../../services/basket/basket.service';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/Rx';
import {BasketItem} from '../../domain/basket-item';
import {StoreService} from '../../services/store/store.service';
import {DeliveryOptions, UserData} from '../../domain/user-data';
import {MatDialog} from '@angular/material';
import {GdprDialogComponent} from './gdpr-dialog/gdpr-dialog.component';
import {Meta, Title} from '@angular/platform-browser';
import {Gtag} from 'angular-gtag';

@Component({
    selector: 'app-basket',
    templateUrl: './basket.component.html',
    styleUrls: ['./basket.component.scss']
})
export class BasketComponent implements OnInit {
    readonly DeliveryOptions = DeliveryOptions;
    protected _destroy$: Subject<boolean> = new Subject<boolean>();
    basketFormGroup: FormGroup;
    personalDataFormGroup: FormGroup;
    deliveryClicked = false;
    deliveryOptions: DeliveryOptions;
    ordered = false;

    constructor(public basketService: BasketService,
                private storeService: StoreService,
                private dialog: MatDialog,
                private titleService: Title,
                private gtag: Gtag,
                private metaService: Meta) {}

    ngOnInit() {
        this.metaService.updateTag({
            name: 'description',
            content: 'Itt adhatod le rendelésedet, választhatsz szállítási módot.'
        });
        this.titleService.setTitle('Kosár');
        this.basketFormGroup = new FormGroup({});
        this.createFormControlFromBasketItems();
        this.personalDataFormGroup = new FormGroup({
            name: new FormControl(null, Validators.required),
            email: new FormControl(null, [Validators.required, Validators.email]),
            phone: new FormControl(),
            address: new FormControl(),
            other: new FormControl(),
            gdpr: new FormControl(false, Validators.requiredTrue)
        });
        this.basketService.basketItems.forEach(basketItem => {
                this.basketFormGroup.get(basketItem.item.identifier).valueChanges.pipe(
                    debounceTime(500),
                    takeUntil(this._destroy$))
                    .subscribe(amount => {
                        if (amount === '0') {
                            this.basketService.removeBasketItem(basketItem);
                        } else {
                            this.basketService.updateBasketItemAmount(basketItem, Number(amount));
                        }
                    });
            }
        );
    }

    private createFormControlFromBasketItems() {
        this.basketService.basketItems.forEach(basketItem =>
            this.basketFormGroup.addControl(basketItem.item.identifier, new FormControl(basketItem.amount)));
    }

    order() {
        this.storeService.createOrder(this.createUserData());
        this.gtag.event('conversion', {
            send_to: 'AW-753150580/Pt2PCNK9qtYBEPTUkOcC',
            transaction_id: ''
        });
        // this.ordered = true;
    }

    delete(basketItem: BasketItem) {
        this.basketService.removeBasketItem(basketItem);
    }

    public getErrorMessage(name: string): string {
        switch (name) {
            case 'name':
                return 'Kérlek add meg a nevedet!';
            case 'email':
                if (this.personalDataFormGroup.get('email').hasError('required')) {
                    return 'Kérlek add meg az email címedet!';
                } else if (this.personalDataFormGroup.get('email').hasError('email')) {
                    return 'Az email címed formátuma nem helyes!';
                } else {
                    return 'Hibás adat!';
                }
                break;
            case 'gdpr':
                if (this.personalDataFormGroup.get('gdpr').hasError('required')) {
                    return 'Kérlek fogadd el az Adatkezelési nyilatkozatot!';
                } else {
                    return 'Hibás adat!';
                }

        }
    }

    private createUserData(): UserData {
        return new UserData(
            this.personalDataFormGroup.get('name').value,
            this.personalDataFormGroup.get('email').value,
            this.personalDataFormGroup.get('phone') ? this.personalDataFormGroup.get('phone').value : null,
            this.personalDataFormGroup.get('address') ? this.personalDataFormGroup.get('address').value : null,
            this.personalDataFormGroup.get('other') ? this.personalDataFormGroup.get('other').value : null,
            this.deliveryOptions.toString()
        );
    }

    openGDPR() {
        this.dialog.open(GdprDialogComponent, {
            autoFocus: true
        });
    }

    deliveryClick() {
        this.deliveryClicked = true;
    }
}
