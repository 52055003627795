import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AppComponent} from './app.component';
import {MenuComponent} from './components/menu/menu.component';
import {routing} from './app.routing';
import {ContactComponent} from './pages/contact/contact.component';
import {FooterComponent} from './components/footer/footer.component';
import {HttpClientModule} from '@angular/common/http';
import {MainComponent} from './pages/main/main.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatSelectModule,
    MatSnackBarModule,
    MatStepperModule,
    MatToolbarModule
} from '@angular/material';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NumbersOnlyDirective} from './directives/number-only.directive';
import {StorageServiceModule} from 'angular-webstorage-service';
import {BasketComponent} from './pages/basket/basket.component';
import {FlipComponent} from './components/flip/flip.component';
import {CommonModule} from '@angular/common';
import {GdprDialogComponent} from './pages/basket/gdpr-dialog/gdpr-dialog.component';
import {HamburgerComponent} from './components/menu/hamburger/hamburger.component';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {CategoryTilesComponent} from './pages/main/category-tiles/category-tiles.component';
import {CategoryTileComponent} from './components/category-tile/category-tile.component';
import {ItemTileComponent} from './components/item-tile/item-tile.component';
import {BannerTileComponent} from './components/banner-tile/banner-tile.component';
import {ProductComponent} from './pages/product/product.component';
import {MarketComponent} from './pages/market/market.component';
import {Ng5SliderModule} from 'ng5-slider';
import {MatRadioModule} from '@angular/material/radio';
import {SafePipe} from './pipes/safe.pipe';
import {SimpleDialogComponent} from './components/simple-dialog/simple-dialog.component';
import {AboutUsComponent} from './pages/about-us/about-us.component';
import {GtagModule} from 'angular-gtag';

@NgModule({
    declarations: [
        AppComponent,
        MenuComponent,
        ContactComponent,
        MainComponent,
        FooterComponent,
        NumbersOnlyDirective,
        BasketComponent,
        BannerTileComponent,
        FlipComponent,
        GdprDialogComponent,
        HamburgerComponent,
        CategoryTilesComponent,
        CategoryTileComponent,
        ItemTileComponent,
        ProductComponent,
        MarketComponent,
        SafePipe,
        SimpleDialogComponent,
        AboutUsComponent
    ],
    imports: [
        MatGridListModule,
        MatDialogModule,
        MatCheckboxModule,
        CommonModule,
        BrowserModule,
        HttpClientModule,
        StorageServiceModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatFormFieldModule,
        MatCardModule,
        MatSnackBarModule,
        MatListModule,
        MatSelectModule,
        MatInputModule,
        MatButtonModule,
        MatDividerModule,
        MatMenuModule,
        MatToolbarModule,
        MatIconModule,
        MatStepperModule,
        MatButtonToggleModule,
        MatRadioModule,
        FlexLayoutModule,
        SwiperModule,
        Ng5SliderModule,
        GtagModule.forRoot({trackingId: 'UA-127478322-2', trackPageviews: true}),
        routing
    ],
    providers: [],
    entryComponents: [
        GdprDialogComponent,
        SimpleDialogComponent,
        HamburgerComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
