import {Injectable} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material';

@Injectable({
    providedIn: 'root'
})
export class IconRegisterService {

    constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
        iconRegistry.addSvgIcon(
            'cart',
            sanitizer.bypassSecurityTrustResourceUrl('assets/icons/cart.svg'));

        iconRegistry.addSvgIcon(
            'bag',
            sanitizer.bypassSecurityTrustResourceUrl('assets/icons/bag.svg'));

        iconRegistry.addSvgIcon(
            'map',
            sanitizer.bypassSecurityTrustResourceUrl('assets/icons/map.svg'));

        iconRegistry.addSvgIcon(
            'diamond',
            sanitizer.bypassSecurityTrustResourceUrl('assets/icons/diamond.svg'));
    }
}
