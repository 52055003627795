import {Component, OnInit} from '@angular/core';
import {ApplicationStateService} from '../../../services/application-state.service';
import {Router} from '@angular/router';
import {ItemCategory} from '../../../domain/item-category';

@Component({
    selector: 'rm-category-tiles',
    templateUrl: './category-tiles.component.html',
    styleUrls: ['./category-tiles.component.scss']
})
export class CategoryTilesComponent implements OnInit {
    itemCategory = ItemCategory;
    columns = 3;

    constructor(private stateService: ApplicationStateService, private router: Router) {
    }

    ngOnInit() {
        this.stateService.isNormalMobileResolution.subscribe(result => this.columns = result ? 1 : this.columns);
        this.stateService.isLargeMobileResolution.subscribe(result => this.columns = result ? 2 : this.columns);
        this.stateService.isTabletResolution.subscribe(result => this.columns = result ? 2 : this.columns);
        this.stateService.isDesktopResolution.subscribe(result => this.columns = result ? 3 : this.columns);
    }

    navigate(to: ItemCategory) {
        this.router.navigate(['/market'], {queryParams: {category: to}});
    }
}
