import { Component, OnInit } from '@angular/core';
import { MenuService } from '../service/menu.service';
import { Router } from '@angular/router';

@Component({
    selector: 'rm-hamburger',
    templateUrl: './hamburger.component.html',
    styleUrls: ['./hamburger.component.scss']
})
export class HamburgerComponent implements OnInit {

    constructor(private menuService: MenuService,
                private router: Router) {
    }

    ngOnInit() {
    }

    close() {
        this.menuService.overlayRef.detach();
    }
}
