import {Injectable} from '@angular/core';
import {Item} from '../../domain/item';
import {ItemCategory} from '../../domain/item-category';

@Injectable({
    providedIn: 'root'
})
export class HoneyStoreService {
    private readonly allItems: Item[];

    constructor() {
        this.allItems = this.collectAcaciaItems()
            .concat(this.collectMixedHoneyItems())
            .concat(this.collectLimeItems())
            .concat(this.collectSilkGrassItems())
            .concat(this.collectRapeItems())
            .concat(this.collectSunFlowerItems())
            .concat(this.collectSpecialHoneyItems())
            .concat(this.collectOtherHoneyItems());
    }

    public collectHoneyItems(): Item[] {
        return this.allItems;
    }

    public getHoneyItemsByType(type: string): Item[] {
        return this.allItems.filter(item => item.identifier.includes(type));
    }

    public getHoneyItemById(type: string): Item {
        return this.allItems.find(item => item.identifier === type);
    }

    public collectAcaciaItems(): Item[] {
        return [new Item('AK1000G', 'Akácméz', '1 kg', 3300, ItemCategory.General,
            'Az egyik legnépszerűbb mézfajta. Hungarikum. Világos színű, harmónikus ízvilág jellemzi. Gyógyhatása: torokfájásra, felsőlégúti megbetegedésre ajánljuk.'),
            new Item('AK500G', 'Akácméz', '1/2 kg', 1800, ItemCategory.General,
                'Az egyik legnépszerűbb mézfajta. Hungarikum. Világos színű, harmónikus ízvilág jellemzi. Gyógyhatása: torokfájásra, felsőlégúti megbetegedésre ajánljuk.'),
            new Item('AK250G', 'Akácméz', '1/4 kg', 1000, ItemCategory.General,
                'Az egyik legnépszerűbb mézfajta. Hungarikum. Világos színű, harmónikus ízvilág jellemzi. Gyógyhatása: torokfájásra, felsőlégúti megbetegedésre ajánljuk.')];
    }

    public collectLimeItems(): Item[] {
        return [new Item('HA1000G', 'Hársméz', '1 kg', 2700, ItemCategory.General,
            'Színe borostyánsága, íze nagyon zamatos. Gyógyhatás: hurutoldó, köhögés- és lázcsillapító hatással bír.'),
            new Item('HA500G', 'Hársméz', '1/2 kg', 1500, ItemCategory.General,
                'Színe borostyánsága, íze nagyon zamatos. Gyógyhatás: hurutoldó, köhögés- és lázcsillapító hatással bír.'),
            new Item('HA250G', 'Hársméz', '1/4 kg', 900, ItemCategory.General,
                'Színe borostyánsága, íze nagyon zamatos. Gyógyhatás: hurutoldó, köhögés- és lázcsillapító hatással bír.')];
    }

    public collectSilkGrassItems(): Item[] {
        return [new Item('SE1000G', 'Selyemfűméz', '1 kg', 2700, ItemCategory.General,
            'Világos színű, kellemes illatú méz. Gyógyhatás: erős gyulladáscsökkentő, vesetisztító.'),
            new Item('SE500G', 'Selyemfűméz', '1/2 kg', 1500, ItemCategory.General,
                'Világos színű, kellemes illatú méz. Gyógyhatás: erős gyulladáscsökkentő, vesetisztító.'),
            new Item('SE250G', 'Selyemfűméz', '1/4 kg', 900, ItemCategory.General,
                'Világos színű, kellemes illatú méz. Gyógyhatás: erős gyulladáscsökkentő, vesetisztító.')];
    }

    public collectRapeItems(): Item[] {
        return [new Item('RE1000G', 'Repceméz', '1 kg', 2300, ItemCategory.General,
            'Krémes állagú, fehér színű, zamatos méz. Gyógyhatás: lúgosító, gyomorsavat csökkentő mézfajta.'),
            new Item('RE500G', 'Repceméz', '1/2 kg', 1300, ItemCategory.General,
                'Krémes állagú, fehér színű, zamatos méz. Gyógyhatás: lúgosító, gyomorsavat csökkentő mézfajta.'),
            new Item('RE250G', 'Repceméz', '1/4 kg', 800, ItemCategory.General,
                'Krémes állagú, fehér színű, zamatos méz. Gyógyhatás: lúgosító, gyomorsavat csökkentő mézfajta.')];
    }

    public collectSunFlowerItems(): Item[] {
        return [new Item('NA1000G', 'Napraforgóméz', '1 kg', 2300, ItemCategory.General,
            'Aranysárga színű, kristályos méz. Gyógyhatás: kiemelkedő a kalcium tartalma, csonterősítő.'),
            new Item('NA500G', 'Napraforgóméz', '1/2 kg', 1300, ItemCategory.General,
                'Aranysárga színű, kristályos méz. Gyógyhatás: kiemelkedő a kalcium tartalma, csonterősítő.'),
            new Item('NA250G', 'Napraforgóméz', '1/4 kg', 800, ItemCategory.General,
                'Aranysárga színű, kristályos méz. Gyógyhatás: kiemelkedő a kalcium tartalma, csonterősítő.')];
    }

    public collectMixedHoneyItems(): Item[] {
        return [new Item('VI1000G', 'Virágméz', '1 kg', 2200, ItemCategory.General,
            'Gazdag aromájú, kristályosodásra hajlamos, sokféle virágról gyűjtött méz. ' +
            'Gyógyhatása: immunerősítő, gyulladáscsökkentő.', 2300),
            new Item('VI500G', 'Virágméz', '1/2 kg', 1200, ItemCategory.General,
                'Gazdag aromájú, kristályosodásra hajlamos, sokféle virágról gyűjtött méz. ' +
                'Gyógyhatása: immunerősítő, gyulladáscsökkentő.', 1300),
            new Item('VI250G', 'Virágméz', '1/4 kg', 700, ItemCategory.General,
                'Gazdag aromájú, kristályosodásra hajlamos, sokféle virágról gyűjtött méz. ' +
                'Gyógyhatása: immunerősítő, gyulladáscsökkentő.')];
    }

    public collectSpecialHoneyItems(): Item[] {
        return [new Item('EH350G', 'Erdei harmat méz', '350 g', 2500, ItemCategory.Special, ''),
            new Item('FE350G', 'Fenyőméz', '350 g', 2500, ItemCategory.Special, ''),
            new Item('MA350G', 'Málnaméz', '350 g', 2500, ItemCategory.Special, ''),
            new Item('KA350G', 'Kakukkfűméz', '350 g', 2500, ItemCategory.Special, ''),
            new Item('LE350G', 'Levendulaméz', '350 g', 2500, ItemCategory.Special, '')];
    }

    public collectOtherHoneyItems(): Item[] {
        return [new Item('PR150G', 'Propoliszos méz', '150 g', 1200, ItemCategory.Other, ''),
            new Item('PR20ML', 'Propolisz', '20 ml', 1200, ItemCategory.Other, ''),
            new Item('MP20G', 'Méhpempő', '20 g', 3500, ItemCategory.Other, ''),
            new Item('MP30G', 'Méhpempő', '30 g', 5000, ItemCategory.Other, ''),
            new Item('MM150G', 'Méhpempős méz', '150 g', 1800, ItemCategory.Other, ''),
            new Item('VP150G', 'Virágpor', '150 g', 1200, ItemCategory.Other, ''),
            new Item('VP70G', 'Virágpor', '70 g', 700, ItemCategory.Other, '')];
    }

    public collectHighlightedItems(): Item[] {
        return [new Item('AK1000G', 'Akácméz', '1 kg', 3300, ItemCategory.General),
            new Item('VI1000G', 'Virágméz', '1kg', 2200, ItemCategory.General, '', 2300),
            new Item('RE1000G', 'Repceméz', '1 kg', 2300, ItemCategory.General, ''),
            new Item('PR20ML', 'Propolisz', '20 ml', 1200, ItemCategory.Other, ''),
            new Item('HA1000G', 'Hársméz', '1 kg', 2700, ItemCategory.General),
        ];
    }
}
