import { Component, OnInit } from '@angular/core';
import { Item } from '../../domain/item';
import { Meta, Title } from '@angular/platform-browser';
import { ApplicationStateService } from '../../services/application-state.service';
import { IconRegisterService } from '../../services/icon-register/icon-register.service';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, startWith, tap } from 'rxjs/operators';
import { ChangeContext, Options } from 'ng5-slider';
import { ItemCategory } from '../../domain/item-category';
import { HoneyStoreService } from '../../services/honey-store/honey-store.service';
import { ItemSorting } from '../../domain/item-sorting';
import { animate, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-market',
    templateUrl: './market.component.html',
    styleUrls: ['./market.component.scss'],
    animations: [
        trigger('showAndHide', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.3s', style({ opacity: 1 })),
            ]),
            transition(':leave', [
                animate('0.3s', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class MarketComponent implements OnInit {

    public ItemCategory: any = ItemCategory;

    private honeyItems: Item[] = [];
    displayHoneyItems: Item[] = [];
    private searchTextFilteredHoneyItems: Item[] = [];
    private categoryFilteredHoneyItems: Item[] = [];
    private priceFilteredHoneyItems: Item[] = [];

    searchTextFormGroup: FormGroup;
    private selectedCategories: ItemCategory[] = [];

    sortingValues: String[] = [ItemSorting.Name, ItemSorting.Price, ItemSorting.Weight, ItemSorting.Category];

    gridListColumns = 4;
    gridListRowHeight = '3:4';

    sliderMinValue = 450;
    sliderMaxValue = 4500;
    sliderOptions: Options = {
        floor: 450,
        ceil: 4500,
        translate: (value: number): string => {
            return value + ' Ft';
        }
    };
    selectedCategory: ItemCategory;

    constructor(private metaService: Meta, private titleService: Title, private stateService: ApplicationStateService,
                private honeyStoreService: HoneyStoreService, private iconRegister: IconRegisterService, private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.titleService.setTitle('Piac');
        this.metaService.updateTag({
            name: 'description',
            content: 'Válogass mézeink közül! Akác, hárs, repce, virágméz...Bőséges a kínálatunk!'
        });

        this.changeColumnsBasedOnViewSize();

        this.initHoneyItems();

        this.searchTextFormGroup = new FormGroup({
            searchText: new FormControl()
        });
        this.filterBySearchText();
        this.filterByRouter();
        this.sort(ItemSorting.Weight);
    }

    private filterByRouter() {
        const category = this.route.snapshot.queryParams.category;
        if (category) {
            this.selectedCategory = category;
            this.filterByCategory(this.selectedCategory);
        }
    }

    scrollToBottom() {
        window.scrollTo(0, document.body.scrollHeight);
    }

    filterByCategory(category: ItemCategory) {
        if (this.selectedCategories.includes(category)) {
            this.selectedCategories = this.selectedCategories.filter(selectedCategory => selectedCategory !== category);
        } else {
            this.selectedCategories.push(category);
        }
        this.categoryFilteredHoneyItems = this.collectCategoryFilteredHoneyItems();
        this.aggregateFilterItems();
    }

    filterByPrice(changeContext: ChangeContext): void {
        this.priceFilteredHoneyItems = this.collectPriceFilteredHoneyItems(changeContext);
        this.aggregateFilterItems();
    }

    private filterBySearchText() {
        this.searchTextFormGroup.get('searchText').valueChanges.pipe(
            startWith(''),
            debounceTime(300),
            distinctUntilChanged(),
            tap((searchText) => {
                this.searchTextFilteredHoneyItems = this.collectSearchTextFilteredHoneyItems(searchText);
                this.aggregateFilterItems();
            })
        ).subscribe();
    }

    private initHoneyItems() {
        this.honeyItems = this.honeyStoreService.collectHoneyItems();
        this.priceFilteredHoneyItems = this.honeyItems;
        this.searchTextFilteredHoneyItems = this.honeyItems;
        this.categoryFilteredHoneyItems = this.honeyItems;
        this.displayHoneyItems = this.honeyItems;
    }

    private collectPriceFilteredHoneyItems(changeContext: ChangeContext) {
        return this.honeyItems.filter(item => item.price >= changeContext.value && item.price <= changeContext.highValue);
    }

    private collectCategoryFilteredHoneyItems(): Item[] {
        if (!(this.selectedCategories.length > 0)) {
            return this.honeyItems;
        }
        return this.honeyItems.filter(item => (this.selectedCategories.includes(item.category)));

    }

    private collectSearchTextFilteredHoneyItems(searchText: string) {
        if (!searchText) {
            return this.honeyItems;
        }
        return this.honeyItems.filter(item => (item.name.toUpperCase().indexOf(searchText.toUpperCase()) > -1));
    }

    private aggregateFilterItems() {
        let currentHoneyItems = this.honeyItems;
        if (this.searchTextFilteredHoneyItems) {
            currentHoneyItems = this.honeyItems.filter(value => this.searchTextFilteredHoneyItems.includes(value));
        }
        if (this.selectedCategories.length > 0) {
            currentHoneyItems = currentHoneyItems.filter(value => this.categoryFilteredHoneyItems.includes(value));
        }

        currentHoneyItems = currentHoneyItems.filter(value => this.priceFilteredHoneyItems.includes(value));
        this.displayHoneyItems = currentHoneyItems;
    }

    private changeColumnsBasedOnViewSize() {
        this.stateService.isNormalMobileResolution.subscribe(result => {
            if (result) {
                this.gridListColumns = 2;
                this.gridListRowHeight = '6:8';
            }
        });
        this.stateService.isLargeMobileResolution.subscribe(result => {
            if (result) {
                this.gridListColumns = 2;
                this.gridListRowHeight = '6:8';
            }
        });
        this.stateService.isTabletResolution.subscribe(result => {
            if (result) {
                this.gridListColumns = 3;
                this.gridListRowHeight = '6:8';
            }
        });
        this.stateService.isDesktopResolution.subscribe(result => {
            if (result) {
                this.gridListColumns = 3;
                this.gridListRowHeight = '6:8';
            }
        });
    }

    sort(value: ItemSorting) {
        switch (value) {
            case ItemSorting.Name:
                this.displayHoneyItems.sort((a, b) => this.alphabeticSorting(a.name.toUpperCase(), b.name.toUpperCase()));
                break;
            case ItemSorting.Category:
                this.displayHoneyItems.sort((a, b) => this.alphabeticSorting(a.category.toUpperCase(), b.category.toUpperCase()));
                break;
            case ItemSorting.Price:
                this.displayHoneyItems.sort((a, b) => a.price - b.price);
                break;
            case ItemSorting.Weight:
                this.displayHoneyItems.sort((a, b) => this.getActualWeight(b.weight) - this.getActualWeight(a.weight));
                break;
        }
    }

    private alphabeticSorting(nameA, nameB) {
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    }

    private getActualWeight(weight: string): number {
        const weightNumber = this.getWeightNumber(weight.substr(0, weight.indexOf(' ')));
        const weightUnit = weight.substr(weight.indexOf(' ') + 1);
        switch (weightUnit) {
            case 'g':
                return weightNumber;
            case 'ml':
                return weightNumber;
            case 'kg':
                return weightNumber * 1000;
            default:
                return weightNumber;
        }
    }

    private getWeightNumber(weightNumberAsString: string): number {
        switch (weightNumberAsString) {
            case '1/2':
                return 0.5;
            case '1/4':
                return 0.25;
            default:
                return Number(weightNumberAsString);
        }
    }
}
