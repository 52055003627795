import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Observable, Subject } from 'rxjs/Rx';
import { IconRegisterService } from '../../services/icon-register/icon-register.service';
import { ApplicationStateService } from '../../services/application-state.service';
import { Item } from '../../domain/item';
import { of as observableOf } from 'rxjs/internal/observable/of';
import { HoneyStoreService } from '../../services/honey-store/honey-store.service';

@Component({
    selector: 'rm-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, AfterViewInit {

    bannerSwiperConfig: SwiperConfigInterface;
    firsBannerTileSubject = new Subject<boolean>();
    secondBannerTileSubject = new Subject<boolean>();
    highlightSwiperConfig: SwiperConfigInterface;
    highlightItems: Observable<Item[]>;

    constructor(private metaService: Meta,
                private titleService: Title,
                private stateService: ApplicationStateService,
                private iconRegister: IconRegisterService,
                private honeyStoreService: HoneyStoreService) {
    }

    ngOnInit() {
        this.titleService.setTitle('Rendelj mézet!');
        this.metaService.updateTag({
            name: 'description',
            content: 'Termelői magyar méz, kedvező áron! Kiszállítás Magyarország egész területén! Garantáltan elégedett lesz a minőséggel!'
        });

        this.createBannerSwiperConfig();
        this.highlightItems = observableOf(this.createHighlightItems());
        this.createHighlightSwiperConfig();
        this.changeHighlightBasedOnViewSize();
    }

    ngAfterViewInit(): void {
        this.firsBannerTileSubject.next(true);
    }

    bannerIndexChanged($event: number) {
        switch ($event) {
            case 0:
                this.firsBannerTileSubject.next(true);
                this.secondBannerTileSubject.next(false);
                break;
            case 1:
                this.firsBannerTileSubject.next(false);
                this.secondBannerTileSubject.next(true);
                break;
        }
    }

    private changeHighlightBasedOnViewSize() {
        this.stateService.isNormalMobileResolution.subscribe(result => result ? this.highlightSwiperConfig.slidesPerView = 2 : null);
        this.stateService.isLargeMobileResolution.subscribe(result => result ? this.highlightSwiperConfig.slidesPerView = 2 : null);
        this.stateService.isTabletResolution.subscribe(result => result ? this.highlightSwiperConfig.slidesPerView = 3 : null);
        this.stateService.isDesktopResolution.subscribe(result => result ? this.highlightSwiperConfig.slidesPerView = 4 : null);
    }

    private createHighlightSwiperConfig() {
        this.highlightSwiperConfig = {
            direction: 'horizontal',
            spaceBetween: 36,
            slidesPerView: 4,
            keyboard: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        };
    }

    private createBannerSwiperConfig() {
        this.bannerSwiperConfig = {
            direction: 'horizontal',
            speed: 800,
            autoplay: {
                delay: 10000,
                waitForTransition: true
            }
        };
    }

    private createHighlightItems(): Item[] {
        return this.honeyStoreService.collectHighlightedItems();
    }
}
