import { Component, Input } from '@angular/core';
import { Item } from '../../domain/item';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BasketService } from '../../services/basket/basket.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasketItem } from '../../domain/basket-item';
import { ApplicationStateService } from '../../services/application-state.service';

@Component({
    selector: 'rm-item-tile',
    templateUrl: './item-tile.component.html',
    styleUrls: ['./item-tile.component.scss'],
    animations: [
        trigger('hover', [
            // IMAGE ANIMATION
            state('fadeIn', style({
                opacity: 1,
            })),
            state('fadeOut', style({
                opacity: 0.7,
            })),
            transition('fadeOut => fadeIn', [
                animate('0.3s')
            ]),
            transition('fadeIn => fadeOut', [
                animate('0.3s')
            ]),
            // TITLE ANIMATION
            state('moveDown', style({
                transform: 'translateY(0px)',
            })),
            state('moveUp', style({
                transform: 'translateY(-5px)',
            })),
            transition('moveDown => moveUp', [
                animate('0.3s')
            ]),
            transition('moveUp => moveDown', [
                animate('0.3s')
            ]),
            // SUBTITLE ANIMATION
            state('hideMove', style({
                transform: 'translateY(0px)',
                filter: 'opacity(0)'
            })),
            state('showMove', style({
                transform: 'translateY(-5px)',
                filter: 'opacity(1)'
            })),
            transition('showMove => hideMove', [
                animate('0.3s')
            ]),
            transition('hideMove => showMove', [
                animate('0.3s')
            ]),
            // BADGE ANIMATION
            state('hide', style({
                filter: 'opacity(0)'
            })),
            state('show', style({
                filter: 'opacity(1)'
            })),
            transition('show => hide', [
                animate('0.3s')
            ]),
            transition('hide => show', [
                animate('0.3s')
            ]),
        ])
    ],
})
export class ItemTileComponent {

    @Input() item: Item;
    isEntered: boolean;
    isTouchscreen: boolean;
    touchCounter: number;

    constructor(private router: Router,
                private basketService: BasketService,
                private snackBar: MatSnackBar,
                private applicationStateService: ApplicationStateService) {
        this.isTouchscreen = applicationStateService.isTouchscreen;
        this.touchCounter = 0;
    }

    mouseEvent(event: string) {
        if (event === 'enter') {
            this.isEntered = true;
        } else if (event === 'leave') {
            this.isEntered = false;
            this.touchCounter = 0;
        }
    }

    addToBasket($event: MouseEvent) {
        $event.stopPropagation();
        this.addItemToBasket();
        this.sendNotificationToUser();
    }

    private addItemToBasket() {
        this.basketService.addBasketItem(new BasketItem(this.item, Number(1)));
    }

    private sendNotificationToUser() {
        this.snackBar.open('Rendelésed a kosárba kerül.', 'Irány a kosár!', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            politeness: 'assertive'
        }).afterDismissed().subscribe((result) => {
            if (result.dismissedByAction) {
                this.router.navigate(['basket']);
            }
        });
    }
}
