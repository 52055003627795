import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HoneyStoreService} from '../../services/honey-store/honey-store.service';
import {Item} from '../../domain/item';
import {BasketItem} from '../../domain/basket-item';
import {BasketService} from '../../services/basket/basket.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

    selectedProductId: string;
    productTypeId: string;
    honeyItemsByType: Item[];
    selectedHoneyItem: Item;
    basketFormGroup: FormGroup;

    constructor(private activatedRoute: ActivatedRoute,
                private honeyStoreService: HoneyStoreService,
                private basketService: BasketService,
                private snackBar: MatSnackBar,
                private router: Router) {
        this.selectedProductId = this.activatedRoute.snapshot.paramMap.get('id');
        this.productTypeId = this.selectedProductId.substring(0, 2);
    }

    ngOnInit() {
        this.honeyItemsByType = this.honeyStoreService.getHoneyItemsByType(this.productTypeId);
        this.selectedHoneyItem = this.honeyStoreService.getHoneyItemById(this.selectedProductId);
        this.basketFormGroup = new FormGroup({
            amount: new FormControl(null, Validators.required)
        });
        this.basketFormGroup.get('amount').setValue(1);
    }

    moveToBasket() {
        this.addBasketItems();
        this.sendNotificationToUser();
    }

    private addBasketItems() {
        this.basketService.addBasketItem(new BasketItem(this.selectedHoneyItem, Number(this.basketFormGroup.get('amount').value)));
    }

    private sendNotificationToUser() {
        this.snackBar.open('Rendelésed a kosárba kerül.', 'Irány a kosár!', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            politeness: 'assertive'
        }).afterDismissed().subscribe((result) => {
            if (result.dismissedByAction) {
                this.router.navigate(['basket']);
            }
        });
    }

    selectHoneyItem(id: string) {
        this.selectedHoneyItem = this.honeyItemsByType.find(i => i.identifier === id);
        this.router.navigate([`/product/${this.selectedHoneyItem.identifier}`]);
    }
}
