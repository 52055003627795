import {Injectable} from '@angular/core';
import {OverlayRef} from '@angular/cdk/overlay';

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    constructor() {
    }

    public overlayRef: OverlayRef;
}
