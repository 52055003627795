import {Item} from './item';

export class BasketItem {
    item: Item;
    amount: number;

    constructor(item: Item, amount: number) {
        this.item = item;
        this.amount = amount;
    }
}
