import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserData } from '../../domain/user-data';
import { Order } from '../../domain/order';
import { BasketService } from '../basket/basket.service';
import { MatDialog } from '@angular/material';
import { SimpleDialogComponent } from '../../components/simple-dialog/simple-dialog.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Injectable({
    providedIn: 'root'
})
export class StoreService {
    private baseUrl = 'https://rendeljmezet.hu/api/mail';

    constructor(
        private basketService: BasketService,
        private http: HttpClient,
        private dialog: MatDialog
    ) {
    }

    createOrder(userData: UserData) {
        const order: Order = {
            user: userData,
            orders: this.basketService.basketItems
        };

        this.http.post(this.baseUrl, order)
            .subscribe(success => {
                this.basketService.clearBasket();
                this.createSuccessNotification();
            }, error => {
                this.createErrorNotification();
            });
    }

    private createSuccessNotification() {
        this.dialog.open(SimpleDialogComponent, {
            width: '400px',
            autoFocus: true,
            scrollStrategy: new NoopScrollStrategy(),
            data: { content: 'Köszönjük a rendelést! Hamarosan felvesszük Önnel a kapcsolatot!' }
        });
    }

    private createErrorNotification() {
        this.dialog.open(SimpleDialogComponent, {
            width: '400px',
            autoFocus: true,
            scrollStrategy: new NoopScrollStrategy(),
            data: { content: 'Sajnos nem sikerült a rendelés! Kérlek vedd fel velünk a kapcsolatot: 06-30-473-4710 vagy rendeljmezet@gmail.com' }
        });
    }
}
