import { Inject, Injectable } from '@angular/core';
import { BasketItem } from '../../domain/basket-item';
import { SESSION_STORAGE, StorageService } from 'angular-webstorage-service';

const BASKET_STORAGE_KEY = 'basket';

@Injectable({
    providedIn: 'root'
})
export class BasketService {
    private _basketItems: BasketItem[];

    constructor(@Inject(SESSION_STORAGE) private storage: StorageService) {
        this._basketItems = this.storage.get(BASKET_STORAGE_KEY) || [];
    }

    addBasketItems(basketItems: BasketItem[]) {
        basketItems.forEach(basketItem => this.addBasketItem(basketItem));
    }

    addBasketItem(basketItem: BasketItem) {
        for (let i = 0; i < this._basketItems.length; i++) {
            if (this._basketItems[i].item.identifier === basketItem.item.identifier) {
                this._basketItems[i].amount += basketItem.amount;
                this.storage.set(BASKET_STORAGE_KEY, this._basketItems);
                return;
            }
        }

        this._basketItems.push(basketItem);
        this.storage.set(BASKET_STORAGE_KEY, this._basketItems);
    }

    removeBasketItem(basketItem: BasketItem) {
        for (let i = 0; i < this._basketItems.length; i++) {
            if (this._basketItems[i] === basketItem) {
                this._basketItems.splice(i, 1);
            }
        }
        this.storage.set(BASKET_STORAGE_KEY, this._basketItems);
    }

    clearBasket() {
        this._basketItems = [];
        this.storage.set(BASKET_STORAGE_KEY, this._basketItems);
    }

    updateBasketItemAmount(basketItem: BasketItem, amount: number) {
        for (let i = 0; i < this._basketItems.length; i++) {
            if (this._basketItems[i].item.identifier === basketItem.item.identifier) {
                this._basketItems[i].amount = amount;
                this.storage.set(BASKET_STORAGE_KEY, this._basketItems);
                return;
            }
        }
    }

    getBasketItemsPrice(): number {
        let sumPrice = 0;
        this._basketItems.forEach(basketItem => {
            sumPrice += (basketItem.amount * basketItem.item.price);
        });
        return sumPrice;
    }

    get basketItems(): BasketItem[] {
        return this._basketItems;
    }

    isBasketEmpty(): boolean {
        return this._basketItems.length === 0;
    }
}
