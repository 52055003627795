import { Component, Input, OnInit } from '@angular/core';

import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
import { Subject } from 'rxjs/Rx';
import { Router } from '@angular/router';
import { ApplicationStateService } from '../../services/application-state.service';

@Component({
    selector: 'rm-banner-tile',
    templateUrl: './banner-tile.component.html',
    animations: [
        // nice stagger effect when showing existing elements
        trigger('list', [
            transition(':enter', [
                // child animation selector + stagger
                query('@item',
                    stagger(300, animateChild())
                )
            ]),
        ]),
        trigger('item', [
            // cubic-bezier for a tiny bouncing feel
            transition(':enter', [
                style({transform: 'scale(0.8)', opacity: 0}),
                animate('1s cubic-bezier(.8,-0.6,0.2,1.5)',
                    style({transform: 'scale(1)', opacity: 1}))
            ]),
            transition(':leave', [
                style({transform: 'scale(1)', opacity: 1, height: '*'}),
                animate('1s cubic-bezier(.8,-0.6,0.2,1.5)',
                    style({transform: 'scale(0.5)', opacity: 0, height: '0px', margin: '0px'}))
            ]),
        ])
    ],
    styleUrls: ['./banner-tile.component.scss']
})
export class BannerTileComponent implements OnInit {
    isVisible = false;
    isNormalMobileResolution: boolean;

    @Input() toggle = new Subject<boolean>();
    @Input() title: string;
    @Input() subTitle: string;
    @Input() newPrice: string;
    @Input() oldPrice: string;
    @Input() url: string;


    constructor(private router: Router, private stateService: ApplicationStateService) {
    }

    ngOnInit() {
        this.stateService.isNormalMobileResolution.subscribe(result => this.isNormalMobileResolution = result);
        this.toggle.subscribe(result => {
            this.isVisible = result;
        });
    }
}
