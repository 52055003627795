import {Component} from '@angular/core';
import {Gtag} from 'angular-gtag';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    emojiHtml: string;

    constructor(gtag: Gtag) {
        this.emojiHtml = '&#129409;';
    }
}
