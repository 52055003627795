import { RouterModule, Routes } from '@angular/router';
import { ContactComponent } from './pages/contact/contact.component';
import { MainComponent } from './pages/main/main.component';
import { BasketComponent } from './pages/basket/basket.component';
import { ProductComponent } from './pages/product/product.component';
import { MarketComponent } from './pages/market/market.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';


const routes: Routes = [
    { path: 'main', component: MainComponent, pathMatch: 'full' },
    { path: 'contact', component: ContactComponent, pathMatch: 'full' },
    { path: 'market', component: MarketComponent, pathMatch: 'full' },
    { path: 'basket', component: BasketComponent, pathMatch: 'full' },
    { path: 'about-us', component: AboutUsComponent, pathMatch: 'full' },
    { path: 'product/:id', component: ProductComponent, pathMatch: 'full' },
    { path: '', redirectTo: '/main', pathMatch: 'full' },
    { path: '**', redirectTo: '/main', pathMatch: 'full' }
];

export const routing = RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
});
