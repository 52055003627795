import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'rm-category-tile',
    templateUrl: './category-tile.component.html',
    styleUrls: ['./category-tile.component.scss']
})
export class CategoryTileComponent implements OnInit {

    @Input() title: string;
    @Input() subTitle: string;
    @Input() imageUrl: string;

    constructor() {
    }

    ngOnInit() {
    }
}
