export enum DeliveryOptions {
    Personal = 'Személyes átvétel',
    OrderHome25km = 'Házhozrendelés - 25 km',
    OrderHome35km = 'Házhozrendelés - 35 km',
    OrderHomeHungary = 'Házhozrendelés - Magyarország',
}

export class UserData {
    name: string;
    email: string;
    phone: string;
    address: string;
    other: string;
    delivery: string;

    constructor(name: string, email: string, phone: string, address: string, other: string, delivery: string) {
        this.name = name;
        this.email = email;
        this.phone = phone;
        this.address = address;
        this.other = other;
        this.delivery = delivery;
    }
}
