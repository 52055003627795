import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

    constructor(private metaService: Meta, private titleService: Title) {
    }

    ngOnInit() {
        this.titleService.setTitle('Rólunk');
        this.metaService.updateTag({ name: 'description', content: 'Kik vagyunk? Mit csinálunk?' });
    }
}
