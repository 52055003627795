import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    constructor(private metaService: Meta, private titleService: Title) {
    }

    ngOnInit() {
        this.titleService.setTitle('Elérhetőség');
        this.metaService.updateTag({ name: 'description', content: 'Bátran keress minket ezeken az elérhetőségeken!' });
    }
}
